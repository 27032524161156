exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-baku-tour-baku-tour-count-down-js": () => import("./../../../src/pages/BakuTour/BakuTourCountDown.js" /* webpackChunkName: "component---src-pages-baku-tour-baku-tour-count-down-js" */),
  "component---src-pages-baku-tour-baku-tour-js": () => import("./../../../src/pages/BakuTour/BakuTour.js" /* webpackChunkName: "component---src-pages-baku-tour-baku-tour-js" */),
  "component---src-pages-company-aml-policy-js": () => import("./../../../src/pages/Company/AML_Policy.js" /* webpackChunkName: "component---src-pages-company-aml-policy-js" */),
  "component---src-pages-company-area-graph-with-slider-js": () => import("./../../../src/pages/Company/AreaGraphWithSlider.js" /* webpackChunkName: "component---src-pages-company-area-graph-with-slider-js" */),
  "component---src-pages-company-careers-js": () => import("./../../../src/pages/Company/Careers.js" /* webpackChunkName: "component---src-pages-company-careers-js" */),
  "component---src-pages-company-commodities-trading-js": () => import("./../../../src/pages/Company/CommoditiesTrading.js" /* webpackChunkName: "component---src-pages-company-commodities-trading-js" */),
  "component---src-pages-company-contact-us-js": () => import("./../../../src/pages/Company/Contact-us.js" /* webpackChunkName: "component---src-pages-company-contact-us-js" */),
  "component---src-pages-company-copy-trading-js": () => import("./../../../src/pages/Company/CopyTrading.js" /* webpackChunkName: "component---src-pages-company-copy-trading-js" */),
  "component---src-pages-company-crypto-trading-js": () => import("./../../../src/pages/Company/CryptoTrading.js" /* webpackChunkName: "component---src-pages-company-crypto-trading-js" */),
  "component---src-pages-company-faq-js": () => import("./../../../src/pages/Company/FAQ.js" /* webpackChunkName: "component---src-pages-company-faq-js" */),
  "component---src-pages-company-forex-marketing-js": () => import("./../../../src/pages/Company/ForexMarketing.js" /* webpackChunkName: "component---src-pages-company-forex-marketing-js" */),
  "component---src-pages-company-ib-partnership-js": () => import("./../../../src/pages/Company/IbPartnership.js" /* webpackChunkName: "component---src-pages-company-ib-partnership-js" */),
  "component---src-pages-company-indices-trading-js": () => import("./../../../src/pages/Company/IndicesTrading.js" /* webpackChunkName: "component---src-pages-company-indices-trading-js" */),
  "component---src-pages-company-legal-documents-js": () => import("./../../../src/pages/Company/Legal-documents.js" /* webpackChunkName: "component---src-pages-company-legal-documents-js" */),
  "component---src-pages-company-metal-trading-js": () => import("./../../../src/pages/Company/MetalTrading.js" /* webpackChunkName: "component---src-pages-company-metal-trading-js" */),
  "component---src-pages-company-our-awards-js": () => import("./../../../src/pages/Company/OurAwards.js" /* webpackChunkName: "component---src-pages-company-our-awards-js" */),
  "component---src-pages-company-pamm-js": () => import("./../../../src/pages/Company/PAMM.js" /* webpackChunkName: "component---src-pages-company-pamm-js" */),
  "component---src-pages-company-payment-slider-js": () => import("./../../../src/pages/Company/PaymentSlider.js" /* webpackChunkName: "component---src-pages-company-payment-slider-js" */),
  "component---src-pages-company-privacy-policy-js": () => import("./../../../src/pages/Company/Privacy_Policy.js" /* webpackChunkName: "component---src-pages-company-privacy-policy-js" */),
  "component---src-pages-company-product-statement-js": () => import("./../../../src/pages/Company/ProductStatement.js" /* webpackChunkName: "component---src-pages-company-product-statement-js" */),
  "component---src-pages-company-regulations-offices-js": () => import("./../../../src/pages/Company/RegulationsOffices.js" /* webpackChunkName: "component---src-pages-company-regulations-offices-js" */),
  "component---src-pages-company-shares-trading-js": () => import("./../../../src/pages/Company/SharesTrading.js" /* webpackChunkName: "component---src-pages-company-shares-trading-js" */),
  "component---src-pages-company-terms-and-conditions-js": () => import("./../../../src/pages/Company/TermsAndConditions.js" /* webpackChunkName: "component---src-pages-company-terms-and-conditions-js" */),
  "component---src-pages-company-trading-calculator-js": () => import("./../../../src/pages/Company/TradingCalculator.js" /* webpackChunkName: "component---src-pages-company-trading-calculator-js" */),
  "component---src-pages-company-welcome-bonus-js": () => import("./../../../src/pages/Company/WelcomeBonus.js" /* webpackChunkName: "component---src-pages-company-welcome-bonus-js" */),
  "component---src-pages-company-who-we-are-js": () => import("./../../../src/pages/Company/Who-we-are.js" /* webpackChunkName: "component---src-pages-company-who-we-are-js" */),
  "component---src-pages-demo-contest-all-all-stocks-banner-js": () => import("./../../../src/pages/DemoContestAll/AllStocksBanner.js" /* webpackChunkName: "component---src-pages-demo-contest-all-all-stocks-banner-js" */),
  "component---src-pages-demo-contest-all-components-contest-registration-js": () => import("./../../../src/pages/DemoContestAll/Components/ContestRegistration.js" /* webpackChunkName: "component---src-pages-demo-contest-all-components-contest-registration-js" */),
  "component---src-pages-demo-contest-all-components-demo-banner-js": () => import("./../../../src/pages/DemoContestAll/Components/DemoBanner.js" /* webpackChunkName: "component---src-pages-demo-contest-all-components-demo-banner-js" */),
  "component---src-pages-demo-contest-all-components-demo-terms-js": () => import("./../../../src/pages/DemoContestAll/Components/DemoTerms.js" /* webpackChunkName: "component---src-pages-demo-contest-all-components-demo-terms-js" */),
  "component---src-pages-demo-contest-all-components-round-instructions-js": () => import("./../../../src/pages/DemoContestAll/Components/RoundInstructions.js" /* webpackChunkName: "component---src-pages-demo-contest-all-components-round-instructions-js" */),
  "component---src-pages-demo-contest-all-components-stocks-icons-js": () => import("./../../../src/pages/DemoContestAll/Components/StocksIcons.js" /* webpackChunkName: "component---src-pages-demo-contest-all-components-stocks-icons-js" */),
  "component---src-pages-demo-contest-all-components-winner-demo-js": () => import("./../../../src/pages/DemoContestAll/Components/WinnerDemo.js" /* webpackChunkName: "component---src-pages-demo-contest-all-components-winner-demo-js" */),
  "component---src-pages-demo-contest-all-demo-contest-js": () => import("./../../../src/pages/DemoContestAll/DemoContest.js" /* webpackChunkName: "component---src-pages-demo-contest-all-demo-contest-js" */),
  "component---src-pages-demo-contest-all-hall-of-fame-js": () => import("./../../../src/pages/DemoContestAll/HallOfFame.js" /* webpackChunkName: "component---src-pages-demo-contest-all-hall-of-fame-js" */),
  "component---src-pages-dubai-tour-dubai-tour-count-down-js": () => import("./../../../src/pages/DubaiTour/DubaiTourCountDown.js" /* webpackChunkName: "component---src-pages-dubai-tour-dubai-tour-count-down-js" */),
  "component---src-pages-dubai-tour-dubai-tour-js": () => import("./../../../src/pages/DubaiTour/DubaiTour.js" /* webpackChunkName: "component---src-pages-dubai-tour-dubai-tour-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-economic-calendar-js": () => import("./../../../src/pages/insights/EconomicCalendar.js" /* webpackChunkName: "component---src-pages-insights-economic-calendar-js" */),
  "component---src-pages-insights-glossary-js": () => import("./../../../src/pages/insights/Glossary.js" /* webpackChunkName: "component---src-pages-insights-glossary-js" */),
  "component---src-pages-markets-cfds-js": () => import("./../../../src/pages/Markets/cfds.js" /* webpackChunkName: "component---src-pages-markets-cfds-js" */),
  "component---src-pages-markets-exchange-traded-futures-js": () => import("./../../../src/pages/Markets/ExchangeTradedFutures.js" /* webpackChunkName: "component---src-pages-markets-exchange-traded-futures-js" */),
  "component---src-pages-markets-forex-js": () => import("./../../../src/pages/Markets/Forex.js" /* webpackChunkName: "component---src-pages-markets-forex-js" */),
  "component---src-pages-markets-index-js": () => import("./../../../src/pages/Markets/index.js" /* webpackChunkName: "component---src-pages-markets-index-js" */),
  "component---src-pages-nfp-nfp-banner-js": () => import("./../../../src/pages/NFP/NFPBanner.js" /* webpackChunkName: "component---src-pages-nfp-nfp-banner-js" */),
  "component---src-pages-nfp-nfp-count-down-js": () => import("./../../../src/pages/NFP/NFPCountDown.js" /* webpackChunkName: "component---src-pages-nfp-nfp-count-down-js" */),
  "component---src-pages-nfp-nfp-fb-contest-js": () => import("./../../../src/pages/NFP/NfpFbContest.js" /* webpackChunkName: "component---src-pages-nfp-nfp-fb-contest-js" */),
  "component---src-pages-nfp-nfp-page-js": () => import("./../../../src/pages/NFP/NFPPage.js" /* webpackChunkName: "component---src-pages-nfp-nfp-page-js" */),
  "component---src-pages-register-demo-js": () => import("./../../../src/pages/register/demo.js" /* webpackChunkName: "component---src-pages-register-demo-js" */),
  "component---src-pages-register-ib-js": () => import("./../../../src/pages/register/ib.js" /* webpackChunkName: "component---src-pages-register-ib-js" */),
  "component---src-pages-register-live-js": () => import("./../../../src/pages/register/live.js" /* webpackChunkName: "component---src-pages-register-live-js" */),
  "component---src-pages-thailand-tour-thailand-tour-contest-count-down-js": () => import("./../../../src/pages/ThailandTour/ThailandTourContestCountDown.js" /* webpackChunkName: "component---src-pages-thailand-tour-thailand-tour-contest-count-down-js" */),
  "component---src-pages-thailand-tour-thailand-tour-contest-js": () => import("./../../../src/pages/ThailandTour/ThailandTourContest.js" /* webpackChunkName: "component---src-pages-thailand-tour-thailand-tour-contest-js" */),
  "component---src-pages-trading-accounts-js": () => import("./../../../src/pages/Trading/Accounts.js" /* webpackChunkName: "component---src-pages-trading-accounts-js" */),
  "component---src-pages-trading-deposit-withdrawal-js": () => import("./../../../src/pages/Trading/Deposit-Withdrawal.js" /* webpackChunkName: "component---src-pages-trading-deposit-withdrawal-js" */),
  "component---src-pages-trading-mt-4-desktop-js": () => import("./../../../src/pages/Trading/MT4Desktop.js" /* webpackChunkName: "component---src-pages-trading-mt-4-desktop-js" */),
  "component---src-pages-trading-mt-4-mobile-js": () => import("./../../../src/pages/Trading/MT4Mobile.js" /* webpackChunkName: "component---src-pages-trading-mt-4-mobile-js" */),
  "component---src-pages-trading-webtrader-js": () => import("./../../../src/pages/Trading/Webtrader.js" /* webpackChunkName: "component---src-pages-trading-webtrader-js" */)
}

